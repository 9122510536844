<template>
  <div>
    <el-form :inline="true" size="mini">
      <el-form-item>
        <el-button icon="el-icon-plus" type="primary" @click="addOperator" size="mini">添加</el-button>
      </el-form-item>
    </el-form>
    <el-table v-loading="loading" :data="data" stripe @row-dblclick="editOperator" border>
      <el-table-column label="用户名" prop="operatorName" show-overflow-tooltip></el-table-column>
      <el-table-column label="登录密码" prop="operatorPassword" show-overflow-tooltip></el-table-column>
      <el-table-column label="属性">
        <template slot-scope="scope">
          <el-tag :type="scope.row.isAdmin?'success':'warning'">{{scope.row.isAdmin?'管理员':'操作员'}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="单据">
        <template slot-scope="scope">
          <el-tag :type="scope.row.shareBill?'success':'warning'">{{scope.row.shareBill?'所有':'自己'}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="状态">
        <template slot-scope="scope">
          <el-tag :type="scope.row.isStop?'danger':'info'">{{scope.row.isStop?'停用':'正常'}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="65">
        <template slot-scope="scope">
          <el-tag type="danger" effect="dark" @click="deleteOperator(scope.row)">删除</el-tag>
        </template>
      </el-table-column>
    </el-table>
    <div>
      <add-edit :item="item" @refTable="getData"></add-edit>
    </div>
  </div>
</template>

<script>
import AddEdit from "@/views/operator/Item";
export default {
  components: {
    AddEdit,
  },
  data() {
    return {
      loading: false,
      data: [],
      item: {},
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.loading = true;
      this.$get("Operator/GetAllOperator")
        .then((r) => {
          this.data = r;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    addOperator() {
      this.item = {
        isAdmin: false,
        isStop: false,
        shareBill: true,
      };
    },
    editOperator(row) {
      this.item = {
        id: row.id,
        operatorPassword: row.operatorPassword,
        operatorName: row.operatorName,
        isAdmin: row.isAdmin,
        isStop: row.isStop,
        shareBill: row.shareBill,
      };
    },
    deleteOperator(row) {
      this.$confirm("将删除【" + row.operatorName + "】, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let loading = this.$loading({
            lock: true,
            text: "删除中...",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)",
          });
          this.$post("Operator/DeleteOperator?id=" + row.id)
            .then((r) => {
              this.$message.success("操作成功！");
              this.getData();
            })
            .finally(() => {
              loading.close();
            });
        })
        .catch(() => {});
    },
  },
};
</script>

<style>
</style>