<template>
  <div>
    <el-dialog
      :title="item.id?'编辑【'+item.operatorName+'】':'新增操作员'"
      :visible.sync="visible"
      append-to-body
      v-dialogDrag
      width="30%"
      :close-on-click-modal="false"
    >
      <el-form :model="form" status-icon :rules="rules" ref="form" label-width="80px">
        <el-form-item label="用户名" prop="operatorName">
          <el-input placeholder="用户名" v-model="form.operatorName" clearable></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="operatorPassword">
          <el-input placeholder="密码" v-model="form.operatorPassword" clearable></el-input>
        </el-form-item>
        <el-form-item label="属性">
          <el-radio-group v-model="form.isAdmin">
            <el-radio :label="true">管理员</el-radio>
            <el-radio :label="false">操作员</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="单据">
          <el-radio-group v-model="form.shareBill" :disabled="form.isAdmin">
            <el-radio :label="true">所有</el-radio>
            <el-radio :label="false">自己</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="状态">
          <el-radio-group v-model="form.isStop">
            <el-radio :label="true">停用</el-radio>
            <el-radio :label="false">正常</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="save" :loading="loading">保存</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: ["item"],
  data() {
    return {
      visible: false,
      loading: false,
      form: {},
      rules: {
        operatorName: [{ required: true, message: "用户名不能为空" }],
        operatorPassword: [{ required: true, message: "密码不能为空" }],
      },
    };
  },
  watch: {
    item: {
      handler: function (value) {
        this.visible = true;
        this.form = {
          ...value,
        };
        if (this.$refs["form"]) {
          this.$refs["form"].resetFields();
        }
      },
      deep: true,
    },
    "form.isAdmin": function (v) {
      if (v) {
        this.form.shareBill = true;
      }
    },
  },
  methods: {
    save() {
      this.$refs["form"].validate((valid) => {
        if (!valid) {
          return;
        }
        this.loading = true;
        let values = this.form;
        if (values.id) {
          this.$post("Operator/EditOperator", values)
            .then((r) => {
              this.visible = false;
              this.$message.success("操作成功！");
              this.$emit("refTable");
            })
            .finally(() => {
              this.loading = false;
            });
        } else {
          this.$post("Operator/AddOperator", values)
            .then((r) => {
              this.visible = false;
              this.$message.success("操作成功！");
              this.$emit("refTable");
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
  },
};
</script>

<style>
</style>